// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/error.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/error.tsx");
  import.meta.hot.lastModified = "1724962854541.6423";
}
// REMIX HMR END

import classNames from "classnames";
export const Error = ({
  message,
  className
}) => {
  if (!message) {
    return null;
  }
  return <div className={classNames("mt-2 rounded-lg border border-solid border-error-2 bg-error-1 p-4 text-sm text-white", className)}>
      {message}
    </div>;
};
_c = Error;
var _c;
$RefreshReg$(_c, "Error");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;